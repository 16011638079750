import React from 'react';

const TrainingPage = () => {
  return (
    <div>
      <h1>Training Page</h1>
    </div>
  );
};

export default TrainingPage;

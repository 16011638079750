import React from 'react';

const Courses = () => {
  return (
    <div className='content'>
      <div className='center-info'>
        <main>
          <h2>Courses page</h2>
        </main>
      </div>
    </div>
  );
};

export default Courses;
